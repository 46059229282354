import axios, { AxiosError } from 'axios';
import { Room, RoomType, RoomConfig } from './types';

export type RequestRoomGET = {
    id?: string;
    urlName?: string;
    excludeMemberLists?: boolean;
};

export const roomGET = async (request: RequestRoomGET, origin: string): Promise<Room> => {
    const resp = await axios({
        method: 'GET',
        url: `${origin}/room`,
        responseType: 'json',
        params: request,
    }).catch((e: AxiosError) => {
        // e.response?.status
        throw e;
    });
    return resp.data;
};

export type RequestRoomsGET = {
    roomLimit?: number;
    archivedOnly?: boolean;
};

export const roomsGET = async (
    token: string,
    request: RequestRoomsGET,
    origin: string
): Promise<Room[]> => {
    const resp = await axios({
        method: 'GET',
        url: `${origin}/rooms`,
        responseType: 'json',
        params: request,
        headers: { Authorization: 'Bearer ' + token },
    }).catch((e: AxiosError) => {
        // e.response?.status
        throw e;
    });
    return resp.data;
};

export type RequestRoomPUT = {
    machineID?: string;
    name: string;
    roomType?: RoomType;
};

export const roomPUT = async (
    token: string,
    request: RequestRoomPUT,
    origin: string
): Promise<Room> => {
    const resp = await axios({
        method: 'PUT',
        url: `${origin}/room`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export type RequestRoomDELETE = {
    id: string;
};

export const roomDELETE = async (
    token: string,
    request: RequestRoomDELETE,
    origin: string
): Promise<void> => {
    await axios({
        method: 'DELETE',
        url: `${origin}/room`,
        headers: { Authorization: 'Bearer ' + token },
        responseType: 'json',
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
};

export type RequestRoomServerPOST = {
    roomID?: string;
    roomURLName?: string;
    machineID?: string;
};

export const roomServerPOST = async (
    request: RequestRoomServerPOST,
    origin: string
): Promise<Room> => {
    const resp = await axios({
        method: 'POST',
        url: `${origin}/room/server`,
        responseType: 'json',
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export type RequestRoomConfigGET = {
    roomID: string;
};

export const roomConfigGET = async (
    request: RequestRoomConfigGET,
    origin: string
): Promise<RoomConfig> => {
    const resp = await axios({
        method: 'GET',
        url: `${origin}/room/config`,
        responseType: 'json',
        params: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export type RequestRoomSettingsPOST = {
    roomID: string;
    open: boolean;
};

export const roomSettingsPOST = async (
    token: string,
    request: RequestRoomSettingsPOST,
    origin: string
): Promise<Room> => {
    const resp = await axios({
        method: 'POST',
        url: `${origin}/room/settings`,
        headers: { Authorization: 'Bearer ' + token },
        responseType: 'json',
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export type RequestRoomModeratorPOST = {
    roomID: string;
    userID: string;
    isMod: boolean;
};

export const roomModeratorPOST = async (
    token: string,
    request: RequestRoomModeratorPOST,
    origin: string
): Promise<Room> => {
    const resp = await axios({
        method: 'POST',
        url: `${origin}/room/moderator`,
        headers: { Authorization: 'Bearer ' + token },
        responseType: 'json',
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export type RequestRoomArchivePOST = {
    roomID: string;
    archive: boolean;
};

export const roomArchivePOST = async (
    token: string,
    request: RequestRoomArchivePOST,
    origin: string
): Promise<void> => {
    await axios({
        method: 'POST',
        url: `${origin}/room/archive`,
        headers: { Authorization: 'Bearer ' + token },
        responseType: 'json',
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
};

export type RequestRoomNamePOST = {
    roomID: string;
    name: string;
};

export const roomNamePOST = async (
    token: string,
    request: RequestRoomNamePOST,
    origin: string
): Promise<void> => {
    await axios({
        method: 'POST',
        url: `${origin}/room/name`,
        headers: { Authorization: 'Bearer ' + token },
        responseType: 'json',
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
};
